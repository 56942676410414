<template>
  <transition name="el-fade-in" mode="out-in">
    <router-view :key="this.$route.path" />
  </transition>
</template>

<script>
export default {
};
</script>

<style >
</style>